import { Moment } from 'moment'
import { FetchStatus } from 'src/assets/types/store/fetchStatus'
import {
  ScheduleType,
  ISchedule,
  ScheduleMethodType
} from 'src/containers/Schedules/types/schedule'
import { SchedulesState } from 'src/store/reducer/schedules'

export const ActionTypes = {
  CREATE_NEW_SCHEDULE: 'CREATE_NEW_SCHEDULE',
  DELETE_SCHEDULE: 'DELETE_SCHEDULE',
  UPDATE_CURRENT_SCHEDULE: 'UPDATE_CURRENT_SCHEDULE',
  INITIALIZE_SCHEDULE: 'INITIALIZE_SCHEDULE',
  SET_SCHEDULE_TYPE: 'SET_SCHEDULE_TYPE',
  SET_FETCH_STATUS: 'SCHEDULES@@SET_FETCH_STATUS',
  SET_VALIDATION_STATUS: 'SCHEDULES@@SET_VALIDATION_STATUS'
} as const

type AddScheduleAction = {
  type: typeof ActionTypes.CREATE_NEW_SCHEDULE
  payload: {
    scheduled_at: Moment
    priority: number
  }
}

type DeleteScheduleAction = {
  type: typeof ActionTypes.DELETE_SCHEDULE
  payload: {
    priority: number
  }
}

type UpdateScheduleAction = {
  type: typeof ActionTypes.UPDATE_CURRENT_SCHEDULE
  payload: {
    priority: number
    scheduled_at: Moment
    method?: ScheduleMethodType
  }
}

type InitializeScheduleAction = {
  type: typeof ActionTypes.INITIALIZE_SCHEDULE
  payload: ISchedule[]
}

type SetTargetScheduleAction = {
  type: typeof ActionTypes.SET_SCHEDULE_TYPE
  payload: ScheduleType
}

type SetFetchStatusAction = {
  type: typeof ActionTypes.SET_FETCH_STATUS
  payload: {
    key: 'schedulesData'
    status: FetchStatus
  }
}

type SetValidationStatusAction = {
  type: typeof ActionTypes.SET_VALIDATION_STATUS
  payload: Partial<SchedulesState['validationStatus']>
}

export type Actions =
  | AddScheduleAction
  | DeleteScheduleAction
  | UpdateScheduleAction
  | InitializeScheduleAction
  | SetTargetScheduleAction
  | SetFetchStatusAction
  | SetValidationStatusAction
