import { cloneDeep } from 'src/lib/page-directory/modules/lodash'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PlaceholderObject = Record<string, any>

const isEmpty = (val: unknown): boolean =>
  val === null || val === undefined

export const removeObjectBy = <T extends PlaceholderObject>(
  object: T,
  conditionMethod?: (val: unknown) => boolean
): T => {
  const newObj = cloneDeep<T>(object)
  const condition = conditionMethod || isEmpty

  const remove = (obj: PlaceholderObject): void => {
    for (const o in obj) {
      if (condition(obj[o])) {
        delete obj[o]
      } else if (typeof obj[o] === 'object') {
        remove(obj[o])
      }
    }
  }

  remove(newObj)
  return newObj
}

export const makeStringBoolBool = <T extends PlaceholderObject>(
  object: T
): T => {
  const newObj = cloneDeep<T>(object)
  const makeBool = (obj: PlaceholderObject): void => {
    for (const o in obj) {
      if (typeof obj[o] === 'string') {
        if (['true', 'false'].includes(obj[o])) {
          obj[o] = !!(obj[o] === 'true')
        }
      } else if (typeof obj[o] === 'object') {
        makeBool(obj[o])
      }
    }
  }
  makeBool(newObj)
  return newObj
}

const convertToBoolean = (str: string): boolean => {
  if (['true', 'false'].includes(str)) {
    return str === 'true'
  }
  return false
}

const makeBoolString = <T extends PlaceholderObject>(object: T): T => {
  const newObj = cloneDeep<T>(object)
  const makeString = (obj: PlaceholderObject): void => {
    for (const o in obj) {
      if (typeof obj[o] === 'boolean') {
        obj[o] = `${obj[o]}`
      } else if (typeof obj[o] === 'object') {
        makeString(obj[o])
      }
    }
  }
  makeString(newObj)
  return newObj
}

export const removeArrayFalse = <T extends PlaceholderObject>(object: T): T => {
  const newObj = cloneDeep<T>(object)

  const shiftFalseOfArray = (targetObj: unknown[]): unknown[] => {
    let newArray = [...targetObj]
    newArray = newArray.filter(Boolean)
    newArray = newArray.filter((arr) => arr !== 'false')
    return newArray
  }

  const crawlObj = (obj: PlaceholderObject): void => {
    for (const o in obj) {
      if (obj[o] instanceof Array) {
        obj[o] = shiftFalseOfArray(obj[o])
      } else if (typeof obj[o] === 'object') {
        crawlObj(obj[o])
      }
    }
  }
  crawlObj(newObj)
  return newObj
}

export const removeBlackListFromObj = <T extends PlaceholderObject>(
  object: T,
  blackList: unknown[]
): T => {
  const newObj = cloneDeep<T>(object)

  const crawlObj = (obj: PlaceholderObject): void => {
    for (const o in obj) {
      if (blackList.includes(o)) {
        delete obj[o]
      } else if (typeof obj[o] === 'object') {
        crawlObj(obj[o])
      }
    }
  }
  crawlObj(newObj)
  return newObj
}
