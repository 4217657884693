import { produce } from 'immer'
import { fetchStatus } from 'src/assets/types/store/fetchStatus'
import { AppState, initialState } from './reducer'

export const STORE_VERSION = 11

export const migrate = async (
  persistedState: AppState & {
    _persist: {
      version: number
      rehydrated: boolean
    }
  },
  version: typeof STORE_VERSION
) => {
  if (
    version !== persistedState?._persist?.version ||
    window.location.search.includes('clear')
  ) {
    return initialState
  }

  const newState = produce(initialState, (draft) => {
    // user
    draft.user = initialState.user
    draft.user.agreementStatus =
      persistedState.user?.agreementStatus ?? initialState.user.agreementStatus
    draft.user.agreementStatus.fetchStatus = fetchStatus.UNFETCHED
  })

  return newState
}
