import * as mixpanel from 'mixpanel-browser'
import { useCallback } from 'react'
import { fetchStatus } from 'src/assets/types/store/fetchStatus'
import { useActiveProposal } from 'src/hooks/useActiveProposal'
import { useAgreements } from 'src/hooks/useAgreements'

type TrackMixpanel = {
  trackMixpanel: () => Promise<void>
}

export const useTrackMixpanel = (): TrackMixpanel => {
  const { activeProposal } = useActiveProposal()
  const { agreementStatus } = useAgreements()

  const trackMixpanel = useCallback(async () => {
    if (!mixpanel.people) return

    const trackProposal = () => {
      mixpanel.people.set({
        applicationStatus: activeProposal.proposal.application_status,
        introductionStatus: activeProposal.proposal.introduction_status,
        searchStatus: activeProposal.proposal.search_status,
      })
    }

    const trackAgreementStatus = () => {
      mixpanel.people.set({
        v2Agreed: agreementStatus.v2_agreed,
        v3Agreed: agreementStatus.v3_agreed,
        v2023decAgreed: agreementStatus.v2023dec_agreed,
        agreed:
          agreementStatus.agreed ||
          agreementStatus.v2_agreed ||
          agreementStatus.v3_agreed ||
          agreementStatus.v2023dec_agreed,
      })
    }

    if (
      activeProposal.fetchStatus !== fetchStatus.UNFETCHED &&
      activeProposal.fetchStatus !== fetchStatus.FETCHING
    ) {
      trackProposal()
    }

    if (
      agreementStatus.fetchStatus === fetchStatus.FETCHED ||
      agreementStatus.fetchStatus === fetchStatus.UPDATED
    ) {
      trackAgreementStatus()
    }
  }, [
    activeProposal,
    agreementStatus.agreed,
    agreementStatus.fetchStatus,
    agreementStatus.v2_agreed,
    agreementStatus.v3_agreed,
    agreementStatus.v2023dec_agreed,
  ])

  return {
    trackMixpanel,
  }
}
