import { css } from 'styled-components'

export const colorDarkGray = '#7E9094'
export const colorGray = '#C7CFD8'
export const colorMediumGray = '#7E9094'
export const colorMain = '#6DC2D7'
export const colorLightBlue = '#F1F4FA'
export const colorPink = '#F29592'
export const colorRed = '#ee8476'
export const colorLightGray = '#E2E7EC'
export const colorBlack = '#0d2930'

export const shadow = {
  bright: 'box-shadow: 0px 0px 3.33333px 0px rgba(0, 0, 0, 0.15)',
  deep: 'box-shadow: 0px 11px 13px -8px rgba(0, 0, 0, 0.05)'
}

export const fontRoboto = `font-family: 'Roboto', 'Open Sans', sans-serif;`

export const fontNoto = `font-family: "Noto Sans", "Hiragino Sans", 
"ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", sans-serif;`

const fontFamily = {
  roboto: css`
    font-family: 'Roboto', 'Open Sans', sans-serif;
  `,
  noto: css`
    font-family: 'Noto Sans', 'Hiragino Sans', 'ヒラギノ角ゴシック',
      'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', sans-serif;
  `,
  notoJp: css`
    font-family: 'Noto Sans JP', 'Noto Sans', 'Hiragino Sans',
      'ヒラギノ角ゴシック', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3',
      sans-serif;
  `
} as const
