import { ThemeProvider } from '@smoothincorg/smooth-ui'
import { AppProps } from 'next/app'
import React, { useMemo } from 'react'
import { SWRConfig } from 'swr'
import { ExternalAppsProvider } from 'src/containers/ExternalAppsProvider/ExternalAppsProvider'
import { wrapper } from 'src/store'
import { ErrorBoundary, ErrorFallback } from 'src/ui/page-directory/ErrorBoundary'

type ExtendedProps = {
  Component: {
    Container?: () => JSX.Element
  }
}

// local環境でmock serverを使用するとエラーが発生してliffが開けないため、コメントアウト
// if (process.env.ENV_NAME === 'local' && process.env.HTTPS !== 'true') {
//   const MockServer = () => require('../../mocks/worker')
//   MockServer()
// }

const swrConfigValue = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
}

const App: React.LFC<AppProps & ExtendedProps> = (props) => {
  const { pageProps, Component } = props
  const ContainerElement = useMemo(
    () => Component.Container || ((p: { children: React.ReactNode }) => <>{p.children}</>),
    [Component.Container]
  )

  return (
    <div id="_app">
      <SWRConfig value={swrConfigValue}>
        <ExternalAppsProvider />
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <ThemeProvider>
            <ContainerElement>
              <Component {...pageProps} />
            </ContainerElement>
          </ThemeProvider>
        </ErrorBoundary>
      </SWRConfig>
    </div>
  )
}

export default wrapper.withRedux(App)
