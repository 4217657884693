import { useSelector } from 'react-redux'

export const useUserId = () => {
  const userIdSelector = useSelector(
    (state) => state.proposal.activeProposal.proposal?.user_id
  )

  return {
    userId: userIdSelector
  }
}
