import * as CONSTANTS_VALUES from './values'
import { ENV_NAME } from 'src/lib/constants/envName'
export {  SPLIT_OPTIONS } from './values'

// 今メンテナンス中かどうか
export const MAINTENANCE = false
// Smooth Line API
export const SMOOTH_LINE_API_URL = CONSTANTS_VALUES.SMOOTH_LINE_API_URLS[ENV_NAME]

// LIFFのID
export const LIFF_ID = CONSTANTS_VALUES.LIFF_IDS[ENV_NAME]

// LINEの友達追加URL
export const LINE_URL = CONSTANTS_VALUES.LINE_FRIEND_URLS[ENV_NAME]
export const LEGACY_LINE_URL = CONSTANTS_VALUES.LEGACY_EFFECTIVE_LIFF_URLS[ENV_NAME]

// slackの notice-errors-liff のwebhookのurl
export const SLACK_ERROR_WEBHOOK_URL = CONSTANTS_VALUES.SLACK_ERROR_WEBHOOK_URLS[ENV_NAME]
// LOGROCKET_ORG Id
export const LOGROCKET_ORG = CONSTANTS_VALUES.LOGROCKET_ORGS[ENV_NAME]
// Googleアナリティクス
export const GA_ID = CONSTANTS_VALUES.GTA_IDS[ENV_NAME]
// デプロイ先のルート
export const BASE_URL = CONSTANTS_VALUES.PUBLIC_URLS[ENV_NAME]
// metaにいれるfacebookのapp_id
export const FB_APP_ID = CONSTANTS_VALUES.FB_APP_IDS[ENV_NAME]
// Contantfulのconstants情報
export const CONTENTFUL_PROPS = CONSTANTS_VALUES.CONTENTFUL_PROPS_VALUES[ENV_NAME]
// Firebaseの情報
export const FIREBASE_PROPS = CONSTANTS_VALUES.FIREBASE_PROPS[ENV_NAME]

// MixpanelのToken
export const MIXPANEL_TOKEN = CONSTANTS_VALUES.MIXPANEL_TOKEN[ENV_NAME]
