import { ENV_NAME, GA_ID } from 'src/lib/constants'

const shouldNotFire = () =>
  typeof window === 'undefined' || typeof gtag === 'undefined' || !gtag

export const gaPageView = async (path: string) => {
  if (shouldNotFire()) return

  gtag('config', GA_ID, {
    page_path: path
  })
  gtag('set', 'page_path', path)
}

export const gaIdentify = async (userId: string) => {
  if (shouldNotFire()) return

  gtag('config', GA_ID, {
    user_id: userId
  })
}

export const gaLogEvent = async (
  eventName: string,
  eventParams: { [key: string]: unknown }
) => {
  if (shouldNotFire()) return
  gtag('event', eventName, {
    ...eventParams,
    debug_mode: ENV_NAME !== 'production'
  })
}
