export const fetchStatus = {
  UNFETCHED: 'UNFETCHED',
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
  UPDATED: 'UPDATED'
} as const

export const isAvailableStatus = (status: FetchStatus) => {
  return [fetchStatus.FETCHED, fetchStatus.UPDATED].some(
    (val) => val === status
  )
}

export type FetchStatus = keyof typeof fetchStatus

type FetchStatusPayload = { fetchStatus: FetchStatus }
