import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect, FC } from 'react'
import { useInitializeApp } from 'src/hooks/useInitializeApp'
import { useTrackMixpanel } from 'src/hooks/useTrackMixpanel/useTrackMixpanel'
import { GA_ID } from 'src/lib/constants'

const GlobalEvent: FC = () => {
  return (
    <Head>
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-140234778-8" />
      <script
        dangerouslySetInnerHTML={{
          __html: `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('set', 'allow_ad_personalization_signals', false);
  gtag('js', new Date());
  gtag('config', '${GA_ID}')`,
        }}
      />
    </Head>
  )
}

export const ExternalAppsProvider: FC = () => {
  const { initializeExternalApps, setPageView } = useInitializeApp()
  const router = useRouter()
  const { trackMixpanel } = useTrackMixpanel()

  useEffect(() => {
    trackMixpanel()
    initializeExternalApps()

    router.events.on('routeChangeComplete', setPageView)
    return () => {
      router.events.off('routeChangeComplete', setPageView)
    }
  }, [initializeExternalApps, router.events, setPageView, trackMixpanel])

  return <GlobalEvent />
}
