/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-empty-interface */
import {
  createStore,
  Dispatch,
  Store,
  Reducer
} from 'redux'
import { Persistor } from 'redux-persist'
import { Actions } from './actions'
import { migrate, STORE_VERSION } from './migrate'
import { reducer, initialState, AppState } from './reducer'

interface IPersister {
  __persistor?: Persistor
}

type PersistedStore<S = AppState> = Store<S> & IPersister

declare module 'react-redux' {
  interface DefaultRootState extends AppState {}
  export function useDispatch<TDispatch = Dispatch<Actions>>(): TDispatch
  export function useStore<S = DefaultRootState>(): PersistedStore<S>
}

const makeConfiguredStore = <R extends Reducer, P = AppState>(
  reducer: R,
  preloadedState: P
): Store => {
  const configuredStore = createStore(
    reducer,
    preloadedState,
  )
  return configuredStore
}

export const generateStore = (): Store => {
  // Server
  if (typeof window === 'undefined') {
    return makeConfiguredStore(reducer as Reducer, initialState)
  }

  // Client
  const { persistStore, persistReducer } = require('redux-persist')

  const storage = require('redux-persist/lib/storage').default

  const persistConfig = {
    version: STORE_VERSION,
    key: 'PERSIST_STATE',
    whitelist: ['simplifiedExamination', 'user'],
    storage,
    timeout: 60 * 60 * 3,
    migrate
  }

  const persistedReducer = persistReducer(persistConfig, reducer)

  const store: PersistedStore = makeConfiguredStore(
    persistedReducer,
    initialState
  )
  store.__persistor = persistStore(store)

  return store
}
