import {
  SearchHousesState,
  ISearchHouses,
  ISearchHousesView,
  ISearchHouse,
  SearchHousesData
} from 'src/assets/types/data/search_houses'
import {
  Areas,
  AreaSelectorData
} from 'src/assets/types/data/search_houses/area_selector'
import { FetchStatus } from 'src/assets/types/store/fetchStatus'
import { AreaSelectorState } from 'src/hooks/useAreaSelector'
import { BypassFormState } from 'src/hooks/useSearchHouses/useSeachHousesBypassForm'
import { BypassResultState } from 'src/hooks/useSearchHouses/useSearchHousesBypassResult'
import { SearchHousesFormType } from 'src/hooks/useSearchHousesFormType/useSearchHousesFormType'
import { ISteps } from 'src/hooks/utils/useSteps'

export const ActionTypes = {
  SET_VALUE: 'search-houses/SET_SEARCH_HOUSES_VALUE',
  MERGE_SEARCH_HOUSES_VALUE: 'search-houses/MERGE_SEARCH_HOUSES_VALUE',
  SET_SEARCH_HOUSES_STEPS_VALUE: 'Ssearch-houses/ET_SEARCH_HOUSES_STEPS_VALUE',
  SET_SEARCH_HOUSES_FETCH_STATUS:
    'search-houses/SET_SEARCH_HOUSES_FETCH_STATUS',
  SET_SEARCH_HOUSE_VALUES: 'search-houses/SET_SEARCH_HOUSE_VALUES',
  SET_VIEWS: 'search-houses/SET_VIEWS',
  SET_UPDATED_STATUS: 'search-houses/SET_UPDATED_STATUS',
  SET_AREA_SELECTOR_DATA: 'search-houses/SET_AREA_SELECTOR_DATA',
  SET_FORM_TYPE: 'search-houses/SET_FORM_TYPE',
  SET_BYPASS_VALUE: 'search-houses/SET_BYPASS_VALUE',
  SET_BYPASS_RESLUT: 'search-houses/SET_BYPASS_RESLUT',
  SET_AREA_SELECTOR_STATE: 'search-houses/SET_AREA_SELECTOR_STATE',
  SET_BYPASS_STATE: 'search-houses/SET_BYPASS_STATE',
  // 消す
  SET_SEARCH_HOUSES_AREAS: 'search-houses/SET_SEARCH_HOUSES_AREAS'
} as const

export const setStepsValue = (
  payload: { [key in keyof ISteps['steps']]?: ISteps['steps'][key] }
) => ({
  type: ActionTypes.SET_SEARCH_HOUSES_STEPS_VALUE,
  payload
})

export const setFetchStatus = (payload: {
  target: keyof Pick<
    SearchHousesState,
    'areaSelector' | 'searchHousesData' | 'steps' | 'view'
  >
  fetchStatus: FetchStatus
}) => ({
  type: ActionTypes.SET_SEARCH_HOUSES_FETCH_STATUS,
  payload
})

export const setValue = (payload: Partial<SearchHousesData>) => ({
  type: ActionTypes.SET_VALUE,
  payload
})

const mergeValues = (payload: ISearchHouses) => ({
  type: ActionTypes.MERGE_SEARCH_HOUSES_VALUE,
  payload
})

export const setSearchHouseValues = (payload: Partial<ISearchHouse>) => ({
  type: ActionTypes.SET_SEARCH_HOUSE_VALUES,
  payload
})

export const setViews = (
  payload: {
    [key in keyof ISearchHousesView]?: ISearchHousesView[key]
  }
) => ({
  type: ActionTypes.SET_VIEWS,
  payload
})

export const setUpdatedStatus = (payload: FetchStatus) => ({
  type: ActionTypes.SET_UPDATED_STATUS,
  payload
})

export const setAreaSelector = (payload: PartOf<AreaSelectorData>) => ({
  type: ActionTypes.SET_AREA_SELECTOR_DATA,
  payload
})

export const setFormType = (payload: SearchHousesFormType) => ({
  type: ActionTypes.SET_FORM_TYPE,
  payload
})

export const setBypassValue = (payload: BypassFormState) => ({
  type: ActionTypes.SET_BYPASS_VALUE,
  payload
})

const setBypassState = (
  payload: (state: BypassFormState) => BypassFormState
) => ({
  type: ActionTypes.SET_BYPASS_STATE,
  payload
})

export const setBypassResult = (payload: BypassResultState) => ({
  type: ActionTypes.SET_BYPASS_RESLUT,
  payload
})

export const setAreaSelectorState = (payload: AreaSelectorState) => ({
  type: ActionTypes.SET_AREA_SELECTOR_STATE,
  payload
})

export const setAreaValues = (payload: PartOf<Areas>) => ({
  type: ActionTypes.SET_SEARCH_HOUSES_AREAS,
  payload
})

export type Actions =
  | ReturnType<typeof setStepsValue>
  | ReturnType<typeof setFetchStatus>
  | ReturnType<typeof setValue>
  | ReturnType<typeof mergeValues>
  | ReturnType<typeof setSearchHouseValues>
  | ReturnType<typeof setViews>
  | ReturnType<typeof setUpdatedStatus>
  | ReturnType<typeof setAreaSelector>
  | ReturnType<typeof setFormType>
  | ReturnType<typeof setBypassValue>
  | ReturnType<typeof setBypassResult>
  | ReturnType<typeof setAreaSelectorState>
  | ReturnType<typeof setAreaValues>
  | ReturnType<typeof setBypassState>
