import { Suggestions } from 'src/assets/types/data/suggestions'
import { FetchStatus } from 'src/assets/types/store/fetchStatus'

export const ActionTypes = {
  SET_SUGGESTIONS: 'suggestions/SET_SUGGESTIONS',
  SET_SUGGESTIONS_STATUS: 'suggestions/SET_SUGGESTIONS_STATUS',
  SET_SUGGESTIONS_MAX_SUGGESTION_ID:
    'suggestions/SET_SUGGESTIONS_MAX_SUGGESTION_ID'
} as const

export const setSuggestions = (payload: Suggestions) => ({
  type: ActionTypes.SET_SUGGESTIONS,
  payload
})

export const setSuggestionsStatus = (payload: FetchStatus) => ({
  type: ActionTypes.SET_SUGGESTIONS_STATUS,
  payload
})

export const setMaxSuggestionId = (payload: string) => ({
  type: ActionTypes.SET_SUGGESTIONS_MAX_SUGGESTION_ID,
  payload
})

export type Actions =
  | ReturnType<typeof setSuggestions>
  | ReturnType<typeof setSuggestionsStatus>
  | ReturnType<typeof setMaxSuggestionId>
