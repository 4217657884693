/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Swal, { SweetAlertOptions } from 'sweetalert2'
import success_icon from 'src/assets/images/icons/success.svg'
import warn_icon from 'src/assets/images/icons/warn.svg'
import { getLiff } from 'src/lib/page-directory/liff'

export const sendingAlert = (options: SweetAlertOptions = {}) =>
  Swal.fire({
    title: '送信中です',
    showCloseButton: false,
    showConfirmButton: false,
    customClass: {
      container: 'swal-custom'
    },
    ...options
  })

export const completeAlert = (
  options: SweetAlertOptions & {
    closeWindowAfter?: boolean
  } = {}
) => {
  const closeWindowAfter = options.closeWindowAfter ?? true

  return Swal.fire({
    title: options?.title || '送信に成功しました',
    imageUrl: success_icon,
    imageWidth: 50,
    confirmButtonText: 'OK',
    customClass: {
      container: 'swal-custom'
    },
    ...options
  }).then(() => {
    if (closeWindowAfter) {
      getLiff().then((liff) => {
        liff.closeWindow()
      })
    }
  })
}
export const closeAlert = () => {
  Swal.close()
}

export const confirmAlert = (options: SweetAlertOptions = {}) =>
  Swal.fire({
    showCancelButton: true,
    reverseButtons: true,
    customClass: { container: 'swal-custom swal-custom-confirm' },
    ...options
  })

export const errorAlert = (options: SweetAlertOptions = {}) =>
  Swal.fire({
    imageUrl: warn_icon,
    customClass: {
      container: 'swal-custom'
    },
    ...options
  })
