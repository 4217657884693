import fetch from 'cross-fetch'
import { useCallback, useMemo } from 'react'
import { useAccessToken } from 'src/hooks/useAccessToken'
import { ENV_NAME } from 'src/lib/constants'
import { getLiff } from 'src/lib/page-directory/liff'

export const useSmoothApi = () => {
  const { accessToken } = useAccessToken()
  const isAccessTokenValid = useMemo(() => !!accessToken.decoded, [accessToken.decoded])
  const getData = useCallback(
    async <T>(url: string) => {
      const liff = await getLiff()
      const isLocalBrowser = ENV_NAME === 'local' && process.env.NEXT_PUBLIC_LINE_USER_ID !== undefined && !liff.isInClient()

      if (!isAccessTokenValid) return

      let headers = {};
      let modifiedUrl = url;

      if (isLocalBrowser) {
        modifiedUrl = url.includes('?') 
        ? `${url}&line_user_id=${process.env.NEXT_PUBLIC_LINE_USER_ID}` 
        : `${url}?line_user_id=${process.env.NEXT_PUBLIC_LINE_USER_ID}`;
      } else {
        headers = {
          Authorization: `Bearer ${accessToken.decoded}`,
        } as const;
      }

      const res = await fetch(modifiedUrl, {
        method: 'GET',
        headers,
      }).catch((err) => {
        console.error(err)
        throw err
      })

      if (res.ok) {
        const data: T = res.status === 200 ? await res.json() : {}
        return data
      }

      const err = new Error(res.statusText)
      console.error(err)
      throw err
    },
    [isAccessTokenValid, accessToken.decoded]
  )

  const postData = useCallback(
    async <T>(data: T, url: string, method: 'POST' | 'PUT' | 'PATCH') => {
      if (!isAccessTokenValid) return

      const liff = await getLiff()
      const isLocalBrowser = ENV_NAME === 'local' && process.env.NEXT_PUBLIC_LINE_USER_ID !== undefined && !liff.isInClient()

      let modifiedData = data;
      const headers: HeadersInit = {
        'Content-Type': 'application/json; charset=utf-8'
      }

      if (isLocalBrowser) {
        modifiedData = { ...data, line_user_id: process.env.NEXT_PUBLIC_LINE_USER_ID };
      } else {
        headers['Authorization'] = `Bearer ${accessToken.decoded}`
      }

      const body = JSON.stringify(modifiedData);

      const res = await fetch(url, {
        headers,
        method,
        body,
      }).catch((err) => {
        throw err
      })

      if (res.ok) return res
      const err = new Error(res.statusText)
      console.error(err)
      throw err
    },
    [isAccessTokenValid, accessToken.decoded]
  )

  return {
    getData,
    postData,
  }
}
