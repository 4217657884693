import { HYDRATE } from 'next-redux-wrapper'
import { Actions, ActionTypes } from '../types/actions/user'
import { fetchStatus } from 'src/assets/types/store/fetchStatus'
import { IUserState } from 'src/assets/types/user'

export const userState: IUserState = {
  lineProps: {
    isInit: false,
    isAuthorized: 'NON_INITIALIZED',
    accessToken: {
      isValid: null,
      decoded: null,
      validationDate: null
    },
    lineProfile: {
      userId: null,
      pictureUrl: null,
      displayName: null
    },
    lineVersion: null,
    lineOS: null
  },
  pixelProps: {
    isInitialized: false
  },
  userProps: {
    trackingId: null,
    landedPage: null
  },
  documentsData: {
    fetchStatus: fetchStatus.UNFETCHED,
    moving_applications: [],
    documents: {
      contract: null
    }
  },
  agreementStatus: {
    clientStatus: {
      kobetsu: false,
      denji: false,
      personal: false,
      advantageAnyway: false
    },
    read: {
      kobetsu: false,
      denji: false,
      personal: false
    },
    agreed: false,
    fetchStatus: fetchStatus.UNFETCHED,
    v2_agreed: false,
    v3_agreed: false,
    v2023dec_agreed: false,
    currentPage: null
  }
}

export const userReducer = (
  state = userState,
  action: Actions | { type: typeof HYDRATE; payload: IUserState }
): IUserState => {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...state,
        ...action.payload
      }
    }
    case ActionTypes.SET_LINE_PROPS: {
      return {
        ...state,
        lineProps: {
          ...state.lineProps,
          ...action.payload
        }
      }
    }
    case ActionTypes.SET_USER_PROPS: {
      return {
        ...state,
        userProps: {
          ...state.userProps,
          ...action.payload
        }
      }
    }
    case ActionTypes.SET_PIXEL_PROPS: {
      return {
        ...state,
        pixelProps: {
          ...state.pixelProps,
          ...action.payload
        }
      }
    }
    case ActionTypes.SET_DOCUMENTS: {
      return {
        ...state,
        documentsData: {
          ...state.documentsData,
          ...action.payload
        }
      }
    }
    case ActionTypes.SET_AGREEMENT_STATUS: {
      return {
        ...state,
        agreementStatus: {
          ...state.agreementStatus,
          ...action.payload
        }
      }
    }
    default:
      return state
  }
}
