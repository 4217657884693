
export const EnvNames = {
  local: 'local',
  development: 'development',
  staging: 'staging',
  production: 'production',
} as const

export const PUBLIC_URLS = {
  local: process.env.NEXT_PUBLIC_URL ?? 'https://localhost:3000',
  development: 'https://app.dev.smooth.jp',
  staging: 'https://app.dev.smooth.jp',
  production: 'https://app.smooth.jp',
} as const

export const GTA_IDS = {
  local: 'G-D07BF1DJDD',
  development: 'G-D07BF1DJDD',
  production: 'G-KRGKV8CNHG',
} as const

export const CONTENTFUL_PROPS_VALUES = {
  local: {
    spaceId: 'farmtz7r01oh',
    accessToken: 't3nhz112-xIsZbPTC27CCWKHqDGsd44zcfGnkecX1bY',
  },
  development: {
    spaceId: 'farmtz7r01oh',
    accessToken: 't3nhz112-xIsZbPTC27CCWKHqDGsd44zcfGnkecX1bY',
  },
  production: {
    spaceId: 'farmtz7r01oh',
    accessToken: 't3nhz112-xIsZbPTC27CCWKHqDGsd44zcfGnkecX1bY',
  },
} as const

const GTM_IDS = {
  local: 'GTM-MTCWTJF',
  development: 'GTM-MTCWTJF',
  production: 'GTM-55WXGG3',
} as const

export const FIREBASE_PROPS = {
  local: {
    apiKey: 'AIzaSyBX64fIcZmKuTrK-6U7hyjog4FwAwV0Wao',
    authDomain: 'smooth-prototype-development.firebaseapp.com',
    databaseURL: 'https://smooth-prototype-development.firebaseio.com',
    projectId: 'smooth-prototype-development',
    storageBucket: 'smooth-prototype-development.appspot.com',
    messagingSenderId: '491201020682',
    appId: '1:491201020682:web:3abd19e0f6e791733f70f3',
    measurementId: 'G-D07BF1DJDD',
  },
  development: {
    apiKey: 'AIzaSyBX64fIcZmKuTrK-6U7hyjog4FwAwV0Wao',
    authDomain: 'smooth-prototype-development.firebaseapp.com',
    databaseURL: 'https://smooth-prototype-development.firebaseio.com',
    projectId: 'smooth-prototype-development',
    storageBucket: 'smooth-prototype-development.appspot.com',
    messagingSenderId: '491201020682',
    appId: '1:491201020682:web:3abd19e0f6e791733f70f3',
    measurementId: 'G-D07BF1DJDD',
  },
  production: {
    apiKey: 'AIzaSyAaj1son6YCjoSMaSLI7QcAkeUnLWRpBHk',
    authDomain: 'smooth-prod.firebaseapp.com',
    databaseURL: 'https://smooth-prod.firebaseio.com',
    projectId: 'smooth-prod',
    storageBucket: 'smooth-prod.appspot.com',
    messagingSenderId: '645807837050',
    appId: '1:645807837050:web:54387c370be6c0180a10c3',
    measurementId: 'G-KRGKV8CNHG',
  },
} as const

export const SMOOTH_LINE_API_URLS = {
  local: process.env.NEXT_PUBLIC_SMOOTH_API_URL ?? 'http://localhost:13001',
  development: 'https://api.app.dev.smooth.jp',
  production: 'https://api.app.smooth.jp',
} as const

const FUNCTION_APIS = {
  local: 'https://asia-northeast1-smooth-prototype-development.cloudfunctions.net/smooth_functions',
  development:
    'https://asia-northeast1-smooth-prototype-development.cloudfunctions.net/smooth_functions',
  production: 'https://asia-northeast1-smooth-prod.cloudfunctions.net/smooth_functions',
} as const

export const FB_APP_IDS = {
  local: '2241835442743796',
  development: '2241835442743796',
  production: '2241835442743796',
} as const

export const LIFF_IDS = {
  local: process.env.NEXT_PUBLIC_LIFF_ID ?? '1653344542-BJpDY91N',
  development: '1653344542-BJpDY91N',
  production: '1653845526-0eGJGLyn',
} as const

export const LINE_FRIEND_URLS = {
  local: 'https://line.me/R/ti/p/%40868qoekz',
  development: 'https://line.me/R/ti/p/%40567wqmmj',
  production: 'https://line.me/R/ti/p/%40167scnqz',
} as const

// レガシーではあるが、これでないと例えばAndroid版TikTokのアプリ内ブラウザからLIFFに遷移しないので、
// 流入経路を把握しつつCVRをキープするためにとても重要
export const LEGACY_EFFECTIVE_LIFF_URLS = {
  local: 'line://app/2000786098-NPopmmLG',
  development: 'line://app/1653344542-BJpDY91N',
  production: 'line://app/1653845526-0eGJGLyn',
} as const

export const SLACK_ERROR_WEBHOOK_URLS = {
  local: 'https://hooks.slack.com/services/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',
  development: 'https://hooks.slack.com/services/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',
  production: 'https://hooks.slack.com/services/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',
} as const

export const SPLIT_OPTIONS = [
  {
    name: '3回 分割手数料 実質年率0%',
    value: 3,
    annual_interest_rate: 0
  },
  {
    name: '6回 分割手数料 実質年率15.0%',
    value: 6,
    annual_interest_rate: 0.15,
  },
  {
    name: '12回 分割手数料 実質年率15.0%',
    value: 12,
    annual_interest_rate: 0.15,
  },
  {
    name: '24回 分割手数料 実質年率15.0%',
    value: 24,
    annual_interest_rate: 0.15,
  },
  {
    name: '36回 分割手数料 実質年率15.0%',
    value: 36,
    annual_interest_rate: 0.15,
  },
  {
    name: '48回 分割手数料 実質年率15.0%',
    value: 48,
    annual_interest_rate: 0.15,
  },
] as const

export const MIXPANEL_TOKEN = {
  local: 'EMPTY',
  development: '4fdffab74940a76dad7983c0f1c101e4',
  production: 'fd70c0d485338c52c4425eff84d9587b',
} as const

export const LOGROCKET_ORGS = {
  local: 'ugjwss/smooth-liff-dev',
  development: 'ugjwss/smooth-liff-dev',
  production: 'ugjwss/smooth-liff',
}
