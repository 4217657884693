import moment from 'moment'
import { HYDRATE } from 'next-redux-wrapper'
import { fetchStatus } from 'src/assets/types/store/fetchStatus'
import { ISchedules } from 'src/containers/Schedules/types'
import {
  ScheduleMethodTypes,
  ScheduleType,
  ScheduleMethodType,
  ScheduleTypes,
  ISchedule
} from 'src/containers/Schedules/types/schedule'
import { Actions, ActionTypes } from 'src/store/types/actions/schedules'

interface IValidationStatus {
  duplicatedArr: number[]
  invalid: boolean
}
export type SchedulesState = {
  schedulesData: ISchedules
  scheduleType: ScheduleType
  validationStatus: IValidationStatus
}

export const schedulesState: SchedulesState = {
  scheduleType: {
    status: fetchStatus.UNFETCHED,
    value: null
  },
  schedulesData: {
    fetchStatus: fetchStatus.UNFETCHED,
    schedules: []
  },
  validationStatus: {
    duplicatedArr: [],
    invalid: false
  }
}

export const schedulesReducer = (
  state = schedulesState,
  action: Actions | { type: typeof HYDRATE; payload: SchedulesState }
): SchedulesState => {
  switch (action.type) {
    case ActionTypes.CREATE_NEW_SCHEDULE: {
      return {
        ...state,
        schedulesData: {
          ...state.schedulesData,
          fetchStatus: fetchStatus.UPDATED,
          schedules: [
            ...state.schedulesData.schedules,
            {
              priority: action.payload.priority,
              status: 'suggested',
              scheduled_at: action.payload.scheduled_at.toISOString(),
              method: ScheduleMethodTypes.NEW,
              schedule_type: state.scheduleType.value
            }
          ]
        }
      }
    }
    case ActionTypes.INITIALIZE_SCHEDULE: {
      if (!action.payload) {
        return state
      }

      const categoryObj: {
        [key in typeof categoryArr[number]]?: Partial<ISchedule>[]
      } = {}
      const categoryArr = Object.values(ScheduleTypes)
      for (const key of categoryArr) {
        const planeKayArray = action.payload
          .filter((schedule) => schedule.schedule_type === key)
          .sort((a, b) => a.priority - b.priority)
          .map((s, i) => ({
            ...s,
            priority: i + 1
          }))
        categoryObj[key] = planeKayArray
      }

      const flatPayload = Object.values(categoryObj)?.flat() as ISchedule[]

      return {
        ...state,
        schedulesData: {
          ...state.schedulesData,
          schedules: [...state.schedulesData.schedules, ...flatPayload]
        }
      }
    }
    case ActionTypes.UPDATE_CURRENT_SCHEDULE: {
      const newSchedulesData = { ...state.schedulesData }

      newSchedulesData.schedules.forEach((schedule, index: number) => {
        let method: ScheduleMethodType | undefined
        switch (action.payload.method) {
          case ScheduleMethodTypes.NEW: {
            method = ScheduleMethodTypes.NEW
            break
          }
          case ScheduleMethodTypes.UPDATE: {
            method = ScheduleMethodTypes.UPDATE
            break
          }
          case undefined: {
            if (
              moment(schedule.scheduled_at).format('YYYY-MM-DD HH:mm') !==
              action.payload.scheduled_at.format('YYYY-MM-DD HH:mm')
            ) {
              method = ScheduleMethodTypes.UPDATE
            }
            break
          }
          default:
            break
        }

        if (
          action.payload.priority === schedule.priority &&
          method &&
          schedule.method !== 'delete'
        ) {
          newSchedulesData.schedules[index] = {
            ...schedule,
            scheduled_at: action.payload.scheduled_at.format(
              'YYYY-MM-DD HH:mm'
            ),
            method
          }
        }
      })

      newSchedulesData.fetchStatus = fetchStatus.UPDATED

      return {
        ...state,
        schedulesData: newSchedulesData
      }
    }

    case ActionTypes.DELETE_SCHEDULE: {
      const schedules = state.schedulesData?.schedules
      const newSchedules = schedules
        .map((s) => {
          if (
            s.schedule_type === state.scheduleType.value &&
            s.priority === action.payload.priority
          ) {
            if (s.method === ScheduleMethodTypes.NEW) {
              return null
            }
            return {
              ...s,
              method: ScheduleMethodTypes.DELETE
            }
          }
          return s
        })
        .filter((s) => !!s)

      return {
        ...state,
        schedulesData: {
          ...state.schedulesData,
          fetchStatus: fetchStatus.UPDATED,
          schedules: newSchedules
        }
      }
    }

    case ActionTypes.SET_SCHEDULE_TYPE: {
      return {
        ...state,
        scheduleType: action.payload
      }
    }

    case ActionTypes.SET_FETCH_STATUS: {
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          fetchStatus: action.payload.status
        }
      }
    }

    case ActionTypes.SET_VALIDATION_STATUS: {
      return {
        ...state,
        validationStatus: {
          ...state.validationStatus,
          ...action.payload
        }
      }
    }

    default: {
      return state
    }
  }
}
