import { parse } from 'querystring'
import fetch from 'cross-fetch'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { useSelector, useStore } from 'react-redux'
import { useUserId } from 'src/hooks/useUserId'
import { ENV_NAME, SLACK_ERROR_WEBHOOK_URL } from 'src/lib/constants'
import { EnvNames } from 'src/lib/constants/values'
import { errorAlert } from 'src/lib/page-directory/modules/alert'

interface IErrorToPost {
  type: string
  message: string | Record<string, string>
  page: string
}

type ErrorType = 'GET' | 'PUT' | 'POST' | 'TIMEOUT' | 'PATCH'

type ErrorObj =
  | {
      status: string | number
      message: string
      url: string
    }
  | string

type HandleError = {
  errorCallback: (error: ErrorObj, errorType: ErrorType, defaultErrorMessage?: string) => void
  postErrorToWebhook: (body: IErrorToPost) => Promise<void>
}

export const useHandleErrors = (): HandleError => {
  const router = useRouter()
  const queryString = router.asPath.split('?')[1]

  const store = useStore()
  const lineProps = useSelector((state) => state.user.lineProps)
  const { userId } = useUserId()
  const isProd = ENV_NAME === EnvNames.production
  const slackErrorUrl = SLACK_ERROR_WEBHOOK_URL
  const userAgent =
    typeof window !== 'undefined' ? window?.navigator?.userAgent?.toLowerCase() : null

  const postErrorToWebhook = useCallback(
    async (body: IErrorToPost) => {
      if (ENV_NAME === 'local') {
        console.error(body)
      }
      const errorBlocks = [
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: '*🚑!!ERROR!!🚒*',
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `*TYPE* ${body.type}`,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `*BODY*: ${body.message}`,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `*TIME*: ${String(new Date())}`,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: userId
              ? `*USER_ID*: ${userId || 'null'}`
              : `*LINE_USER_ID*: ${lineProps.lineProfile.userId || 'null'}`,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `*OS*: ${lineProps.lineOS || 'null'}`,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `*LINE_VERSION*: ${lineProps.lineVersion || 'null'}`,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `*router*:\`\`\`${JSON.stringify(
              {
                pathname: router.pathname,
                query: queryString,
                hash: parse(window.location.hash),
              },
              null,
              '\t'
            )}\`\`\``,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `*USER_AGENT*:\`\`\`${JSON.stringify(userAgent, null, '\t')}\`\`\``,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: '________________________________________',
          },
        },
      ]
      await fetch(slackErrorUrl, {
        body: `${JSON.stringify({
          text: body.type,
          blocks: JSON.stringify(errorBlocks),
        })}`,
        mode: 'cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        method: 'POST',
      })
    },
    [
      lineProps.lineOS,
      lineProps.lineProfile.userId,
      lineProps.lineVersion,
      router.pathname,
      queryString,
      slackErrorUrl,
      userAgent,
      userId,
    ]
  )

  const errorCallback = useCallback(
    (error: ErrorObj, errorType: ErrorType, defaultErrorMessage?: string) => {
      store.__persistor?.purge()

      if (typeof window !== 'undefined') {
        window.localStorage?.clear()
      }

      let errorMessage = defaultErrorMessage
      if (!errorMessage) {
        switch (errorType) {
          case 'GET': {
            errorMessage = 'データの取得に失敗しました'
            break
          }
          case 'PUT':
          case 'POST': {
            errorMessage = 'データの送信に失敗しました'
            break
          }
          case 'TIMEOUT': {
            errorMessage =
              'コンテンツの読み込みに失敗しました。通信状態などを確認の上、再度お試しください。'
            break
          }
          default: {
            errorMessage = 'エラーが発生しました'
            break
          }
        }
      }

      const message =
        typeof error === 'string'
          ? error
          : `\`\`\`{\nstatus: ${error.status}\nmessage: ${error.message}\nurl: (${error.url})\n}\`\`\``

      let currentUrl = 'undefined'

      if (typeof window !== 'undefined') {
        currentUrl = encodeURI(window.location.href)
      }

      const errorToPost: IErrorToPost = {
        type: errorType,
        message,
        page: currentUrl,
      }

      postErrorToWebhook(errorToPost)

      errorAlert({
        imageWidth: 50,
        html: `
        <small class="alert">
          ${errorMessage}
          ${`<strong class="alert__content">
              ${isProd ? '' : `(${JSON.stringify(errorToPost, null, '\n')})`}
            </strong>`}
        </small>
      `,
      })
    },
    [store.__persistor, postErrorToWebhook, isProd]
  )
  return { errorCallback, postErrorToWebhook }
}
