import { Actions, ActionTypes } from '../types/actions/user'
import {
  ILineProps,
  IPixelProps,
  IUserProps,
  AgreementStatusState
} from 'src/assets/types/user'
import { IDocumentsData } from 'src/containers/Documents/types'

export const setLineProps = (
  lineProp: RequireOne<{ [key in keyof ILineProps]?: ILineProps[key] }>
): Actions => ({
  type: ActionTypes.SET_LINE_PROPS,
  payload: lineProp
})

const setPixelProps = (
  pixelProp: RequireOne<{ [key in keyof IPixelProps]?: IPixelProps[key] }>
): Actions => ({
  type: ActionTypes.SET_PIXEL_PROPS,
  payload: pixelProp
})

export const setDocuments = (documentsData: IDocumentsData): Actions => ({
  type: ActionTypes.SET_DOCUMENTS,
  payload: documentsData
})

export const setUserProps = (
  userProp: RequireOne<{ [key in keyof IUserProps]?: IUserProps[key] }>
): Actions => ({
  type: ActionTypes.SET_USER_PROPS,
  payload: userProp
})

export const setAgreementStatus = (
  agreementStatus: Partial<AgreementStatusState>
): Actions => ({
  type: ActionTypes.SET_AGREEMENT_STATUS,
  payload: agreementStatus
})
