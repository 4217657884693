import React, { MouseEvent } from 'react'
import styled, { css } from 'styled-components'
import { colorRed } from 'src/assets/styles/variable'

type Props = {
  onClick?: () => void
  disabled?: boolean
}

const Component: React.FCX<Props> = (props) => {
  const { onClick = (e: MouseEvent) => e.preventDefault() } = props

  return (
    <button
      type="button"
      className={`${props.className} ${
        props.disabled && 'disabled'
      } red-button`}
      onClick={onClick}
    >
      <span className={`${props.className}__content`}>{props.children}</span>
    </button>
  )
}

const StyledComponent = styled(Component)`
  ${(props) =>
    props.onClick &&
    css`
      * {
        pointer-events: none;
      }
    `}

  border: none;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 8px;
  font-weight: bold;
  box-shadow: 0px 2px 8px rgba(110, 195, 216, 0.24);

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    background: #cb7165;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &::before {
    content: '';
    position: absolute;
    right: 1px;
    bottom: 2px;
    background: ${colorRed};
    border-radius: 8px;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  &__content,
  &__content * {
    z-index: 3;
    position: relative;
    color: #ffffff;
  }
`

export const RedButton = StyledComponent
