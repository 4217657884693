import produce from 'immer'
import { SuggestionsState } from 'src/assets/types/data/suggestions'
import { fetchStatus } from 'src/assets/types/store/fetchStatus'
import { Actions, ActionTypes } from 'src/store/actions/suggestions'

export const suggestionsInitialState: SuggestionsState = {
  suggestions: {
    status: fetchStatus.UNFETCHED,
    value: []
  },
  maxSuggestionId: null
}

export const suggestionsReducer = (
  state = suggestionsInitialState,
  action: Actions
): SuggestionsState => {
  switch (action.type) {
    case ActionTypes.SET_SUGGESTIONS: {
      return produce(state, (draft) => {
        draft.suggestions.status = fetchStatus.FETCHED
        draft.suggestions.value = [
          ...state.suggestions.value,
          ...action.payload
        ]
      })
    }

    case ActionTypes.SET_SUGGESTIONS_STATUS: {
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          status: action.payload
        }
      }
    }
    case ActionTypes.SET_SUGGESTIONS_MAX_SUGGESTION_ID: {
      return {
        ...state,
        maxSuggestionId: action.payload
      }
    }
    default: {
      return state
    }
  }
}
