import { HYDRATE } from 'next-redux-wrapper'
import { combineReducers, PreloadedState, Reducer } from 'redux'
import { propertiesReducer as properties, propertiesState } from './properties'
import { proposalReducer as proposal, proposalState } from './proposal'
import { schedulesReducer as schedules, schedulesState } from './schedules'
import {
  searchHousesReducer as searchHouses,
  searchHousesState
} from './search-houses'
import {
  suggestionsReducer as suggestions,
  suggestionsInitialState
} from './suggestions'
import { userReducer as user, userState } from './user'
import { Actions } from 'src/store/actions'

const defaultStateValue = {
  user: userState,
  searchHouses: searchHousesState,
  schedules: schedulesState,
  proposal: proposalState,
  properties: propertiesState,
  suggestions: suggestionsInitialState
}

export type AppState = typeof defaultStateValue

export const initialState: PreloadedState<AppState> = defaultStateValue

const combinedReducer = combineReducers({
  user,
  searchHouses,
  schedules,
  proposal,
  properties,
  suggestions
})

const crossSliceReducer = (state: AppState, action: Actions) => {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...state,
        properties: action.payload.properties
      }
    }
    default:
      return state
  }
}

export const rootReducer: Reducer<AppState, Actions> = (state, action) => {
  const intermediateState = combinedReducer(state, action)
  const finalState = crossSliceReducer(intermediateState, action)
  return finalState
}
