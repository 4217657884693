import produce from 'immer'
import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchStatus } from 'src/assets/types/store/fetchStatus'
import { AgreementStatusState } from 'src/assets/types/user'
import { useAccessToken } from 'src/hooks/useAccessToken'
import { useSmoothApi } from 'src/hooks/utils/useSmoothApi'
import { SMOOTH_LINE_API_URL } from 'src/lib/constants'
import { setAgreementStatus } from 'src/store/actions/user'

interface IAgreementRes {
  agreement: boolean
  v2_agreement: boolean
  v3_agreement: boolean
  v2023dec_agreement: boolean
}

export const useAgreements = () => {
  const { accessToken } = useAccessToken()
  const { getData } = useSmoothApi()
  const agreementStatus = useSelector((state) => state.user.agreementStatus)
  const dispatch = useDispatch()

  const handleChangeAgreement = (
    target: keyof AgreementStatusState['clientStatus']
  ) => {
    dispatch(
      setAgreementStatus(
        produce(agreementStatus, (draft) => {
          draft.fetchStatus = fetchStatus.UPDATED
          draft.clientStatus[target] = !agreementStatus.clientStatus[target]
        })
      )
    )
  }

  const handleAgreement = useCallback(
    (target: keyof AgreementStatusState['clientStatus']) => {
      dispatch(
        setAgreementStatus(
          produce(agreementStatus, (draft) => {
            draft.fetchStatus = fetchStatus.UPDATED
            draft.clientStatus[target] = true
          })
        )
      )
    },
    [agreementStatus, dispatch]
  )

  const handleDisAgreement = (
    target: keyof AgreementStatusState['clientStatus']
  ) => {
    dispatch(
      setAgreementStatus(
        produce(agreementStatus, (draft) => {
          draft.clientStatus[target] = false
        })
      )
    )
  }

  const getAgreementsFromServer = useCallback(async () => {
    const url = `${SMOOTH_LINE_API_URL}/api/v4/agreements`
    const data = await getData<IAgreementRes>(url)
    if (data) {
      return data
    }
    throw new Error('no data')
  }, [getData])

  const getAgreements = useCallback(async () => {
    if (
      !accessToken.decoded ||
      agreementStatus.fetchStatus !== fetchStatus.UNFETCHED
    ) {
      return
    }

    dispatch(
      setAgreementStatus({
        ...agreementStatus,
        fetchStatus: fetchStatus.FETCHING
      })
    )

    const serverAgreementStatus = await getAgreementsFromServer()

    if (serverAgreementStatus) {
      dispatch(
        setAgreementStatus({
          ...agreementStatus,
          agreed: serverAgreementStatus.v2023dec_agreement,
          v2023dec_agreed: serverAgreementStatus.v2023dec_agreement,
          v3_agreed: serverAgreementStatus.v3_agreement,
          v2_agreed: serverAgreementStatus.v2_agreement,
          fetchStatus: fetchStatus.FETCHED
        })
      )
      return
    }

    dispatch(
      setAgreementStatus({
        ...agreementStatus,
        fetchStatus: fetchStatus.FETCHED
      })
    )
  }, [accessToken.decoded, agreementStatus, dispatch, getAgreementsFromServer])

  const handleAdvantageAnywayClick = (args?: { callback?: () => void }) => {
    args?.callback && args.callback()
    if (!agreementStatus.clientStatus.advantageAnyway) {
      handleAgreement('advantageAnyway')
      return
    }
    handleDisAgreement('advantageAnyway')
  }

  const setAgreementsState = useCallback(
    (val: Partial<AgreementStatusState>) => {
      dispatch(setAgreementStatus(val))
    },
    [dispatch]
  )

  const setReadStatus = useCallback(
    (val: AgreementStatusState['read']) => {
      const state = {
        ...agreementStatus.read,
        ...val
      }

      if (state === agreementStatus.read) return

      dispatch(
        setAgreementStatus({
          read: {
            ...agreementStatus.read,
            ...val
          }
        })
      )
    },
    [agreementStatus, dispatch]
  )

  return {
    agreementStatus,
    handleAgreement,
    handleDisAgreement,
    handleAdvantageAnywayClick,
    setAgreementsState,
    setReadStatus,
    getAgreements,
    handleChangeAgreement
  }
}
