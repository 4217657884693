import { useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { LIFF_ID } from 'src/lib/constants'
import { getLiff } from 'src/lib/page-directory/liff'
import { getHashParams } from 'src/lib/page-directory/utils/getHashParams'
import { setLineProps } from 'src/store/actions/user'

const accessTokenActions = {
  get: async () => {
    const liff = await getLiff()
    const accessTokenFromLiff =
      getHashParams()?.access_token ?? liff.getAccessToken()

    return accessTokenFromLiff
  },

  refresh: async () => {
    const liff = await getLiff()
    await liff.init({ liffId: LIFF_ID })
    return liff.getAccessToken()
  },

  validate: async (token: string) => {
    const res = await fetch(
      'https://api.line.me/oauth2/v2.1/verify' + `?access_token=${token}`
    )

    if (res.ok) return true
    return false
  }
}

export const useAccessToken = () => {
  const storeAccessToken = useSelector(
    (state) => state.user.lineProps?.accessToken
  )
  const dispatch = useDispatch()
  const accessToken = useMemo(() => storeAccessToken, [storeAccessToken])

  const fetcher = useCallback(async () => {
    if (!storeAccessToken.decoded) {
      const decoded = await accessTokenActions.get()
      const isValid = await accessTokenActions.validate(decoded)

      const accessToken = {
        decoded,
        isValid,
        validationDate: isValid ? new Date().toString() : null
      }

      dispatch(setLineProps({ accessToken }))
      return
    }
  }, [dispatch, storeAccessToken.decoded])

  const getAccessToken = useCallback(async (): Promise<void> => {
    await fetcher()
  }, [fetcher])

  return {
    accessToken,
    getAccessToken
  }
}
