/* eslint-disable react/button-has-type */
import React from 'react'
import styled from 'styled-components'
import {
  fontRoboto,
  colorGray,
  shadow,
  colorMain
} from 'src/assets/styles/variable'

type Props = {
  onClick?:
    | ((e: React.MouseEvent) => void)
    | ((e: React.MouseEvent) => Promise<void>)
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  color?: 'main' | 'white'
  id?: string
}

const Component: React.FCX<Props> = (props) => {
  const { className, children, onClick, disabled, type, id } = props

  return (
    <button
      id={id}
      type={type || 'button'}
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

const StyledComponent = styled(Component)`
  ${fontRoboto}
  padding: 0;
  ${(props): string => {
    if (props.color === 'white')
      return `
        background: #ffffff;
        border: 2px solid ${colorMain};
        color: ${colorMain};
        line-height: 44px;
      `
    return `
      background: linear-gradient(90deg, #86d0e2 0%, #6ec3d8 100%);
      border: none;
      color: #ffffff;
      line-height: 48px;
    `
  }};
  box-shadow: 0px 2px 8px rgba(110, 195, 216, 0.24);
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  height: 48px;
  width: 100%;
  outline: none;
  appearance: none;
  cursor: pointer;
  * {
    pointer-events: none;
  }
  &:disabled {
    background: ${colorGray};
    pointer-events: none;
    cursor: not-allowed;
    ${shadow.bright};
  }
`
export const MainButton = StyledComponent
