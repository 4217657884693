import { PropertiesState } from 'src/assets/types/data/properties'

export const ActionTypes = {
  SET_PROPERTIES: 'properties/SET_PROPERTIES',
  SET_ACTIVE_PROPERTY_UUID: 'properties/SET_SET_ACTIVE_PROPERTY_UUID',
  SET_PROPERTIES_CATEGORIES: 'properties/SET_PROPERTIES_CATEGORIES'
} as const

export const setProperties = (
  payload: Partial<PropertiesState['propertiesState']>
) => ({
  type: ActionTypes.SET_PROPERTIES,
  payload
})

const setActivePropertyUuid = (
  payload: PropertiesState['propertiesState']['activePropertyUuid']
) => ({
  type: ActionTypes.SET_ACTIVE_PROPERTY_UUID,
  payload
})

export const setPropertiesCategories = (
  payload: Partial<PropertiesState['propertiesCategories']>
) => ({
  type: ActionTypes.SET_PROPERTIES_CATEGORIES,
  payload
})

export type Actions =
  | ReturnType<typeof setProperties>
  | ReturnType<typeof setActivePropertyUuid>
  | ReturnType<typeof setPropertiesCategories>
