import { produce } from 'immer'
import { PropertiesState } from 'src/assets/types/data/properties'
import { fetchStatus } from 'src/assets/types/store/fetchStatus'
import { removeObjectBy } from 'src/lib/page-directory/utils/typeHelper'
import { Actions } from 'src/store/actions'
import { ActionTypes } from 'src/store/actions/properties'

export const propertiesState: PropertiesState = {
  propertiesState: {
    fetchStatus: fetchStatus.UNFETCHED,
    properties: [],
    activePropertyUuid: null
  },
  propertiesCategories: {
    publishedProperties: {
      status: fetchStatus.UNFETCHED,
      value: []
    },
    unpublishedProperties: {
      status: fetchStatus.UNFETCHED,
      value: []
    },
    unknownProperties: {
      status: fetchStatus.UNFETCHED,
      value: []
    }
  }
}

export const propertiesReducer = (
  state = propertiesState,
  action: Actions
): PropertiesState => {
  switch (action.type) {
    case ActionTypes.SET_PROPERTIES: {
      const payloadHasProperty = !!action.payload.properties?.length

      const storeProperties = [...(state.propertiesState.properties ?? [])]

      const propertiesToKeep = storeProperties.filter(
        (property) =>
          !action.payload.properties?.some(
            (d) => String(d.id) === String(property.id)
          )
      )

      const properties = [...propertiesToKeep]

      if (payloadHasProperty) {
        action.payload.properties.forEach((value) => {
          const sameProperty = storeProperties.find(
            (property) => String(property.id) === String(value.id)
          )

          const isAlreadyInStore = !!sameProperty

          if (isAlreadyInStore) {
            const mergedProperty = {
              ...removeObjectBy(sameProperty),
              ...removeObjectBy(value)
            }
            properties.push(mergedProperty)
          }

          if (!isAlreadyInStore) {
            properties.push(value)
          }
        })
      }

      return {
        ...state,
        propertiesState: {
          ...state.propertiesState,
          fetchStatus:
            action.payload.fetchStatus ?? state.propertiesState.fetchStatus,
          properties,
          activePropertyUuid:
            action.payload.activePropertyUuid ??
            state.propertiesState.activePropertyUuid
        }
      }
    }
    case ActionTypes.SET_ACTIVE_PROPERTY_UUID: {
      return {
        ...state,
        propertiesState: {
          ...state.propertiesState,
          activePropertyUuid: action.payload
        }
      }
    }
    case ActionTypes.SET_PROPERTIES_CATEGORIES: {
      return produce(state, (draft) => {
        Object.entries(action.payload).forEach(([key, value]) => {
          draft.propertiesCategories[
            // propertiesCategoriesのキー以外入らない（はず）のでアサーション
            key as keyof typeof state.propertiesCategories
          ] = value
        })
      })
    }
    default: {
      return state
    }
  }
}
