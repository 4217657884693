import produce from 'immer'
import { HYDRATE } from 'next-redux-wrapper'
import { ProposalsState } from 'src/assets/types/data/proposal'
import { fetchStatus } from 'src/assets/types/store/fetchStatus'
import { Actions } from 'src/store/actions'
import { ActionTypes } from 'src/store/actions/proposal'


export const proposalState: ProposalsState = {
  activeProposal: {
    fetchStatus: fetchStatus.UNFETCHED,
    proposal: null,
  },
  store: {
    store: {
      fetchStatus: fetchStatus.UNFETCHED,
      store: {
        name: null,
        prefecture: null,
        postal_code: null,
        address: null,
        access: null,
        business_hours_open: 0,
        business_hours_close: 2400,
        business_hours_special: [],
        holiday: [],
        holiday_text: null,
      },
    },
    storeImages: {
      fetchStatus: fetchStatus.UNFETCHED,
      store_images: [
        {
          id: null,
          store_id: null,
          file_name: null,
          file_extension: null,
          file_type: null,
          s3_bucket_key_path: null,
          full_path: null,
          enabled: null,
          created_at: null,
          updated_at: null,
        },
      ],
    },
    storeHolidays: {
      fetchStatus: fetchStatus.UNFETCHED,
      store_holidays: [
        {
          id: null,
          store_id: null,
          scheduled_on: null,
          created_at: null,
          updated_at: null,
        },
      ],
    },
  },
  movingApplications: {
    activeMovingApplication: {
      fetchStatus: fetchStatus.UNFETCHED,
      moving_application: null,
      fixed_initial_cost_documents: null,
      summary_initial_cost_documents: null,
    },
    movingApplications: {
      fetchStatus: fetchStatus.UNFETCHED,
      moving_applications: null,
    },
    tempPaymentPromises: {
      steps: { current: 1, max: 3 },
      checkedBank: null,
      formState: {
        prop_application_confirmed: false,
        amount_confirmed: false,
        payday: '',
        payday_confirmed: false,
        splitAmount: null,
      },
      tempAccountTransferCalendar: {
        value: null,
        fetchStatus: fetchStatus.UNFETCHED,
        fetchedPayday: null,
        fetchedBankCode: null,
        fetchedVer202402: false,
      },
    },
  },
  mainExaminationScore: {
    status: fetchStatus.UNFETCHED,
    value: {
      result: null,
      lendableLoan: null,
    },
  },
}

export const proposalReducer = (
  state = proposalState,
  action: Actions | { type: typeof HYDRATE; payload: ProposalsState }
): ProposalsState => {
  switch (action.type) {
    case ActionTypes.SET_ACTIVE_PROPOSAL: {
      return {
        ...state,
        activeProposal: {
          fetchStatus: action.payload.fetchStatus || state.activeProposal.fetchStatus,
          proposal: {
            ...state.activeProposal.proposal,
            ...action.payload.proposal,
          },
        },
      }
    }
    case ActionTypes.SET_STORE: {
      return {
        ...state,
        store: {
          ...state.store,
          store: {
            ...state.store.store,
            ...action.payload,
          },
        },
      }
    }
    case ActionTypes.SET_STORE_IMAGE: {
      return {
        ...state,
        store: {
          ...state.store,
          storeImages: {
            ...state.store.storeImages,
            ...action.payload,
          },
        },
      }
    }
    case ActionTypes.SET_STORE_HOLIDAY: {
      return {
        ...state,
        store: {
          ...state.store,
          storeHolidays: {
            ...state.store.storeHolidays,
            ...action.payload,
          },
        },
      }
    }
    case ActionTypes.SET_MOVING_APPLICATIONS: {
      return {
        ...state,
        movingApplications: {
          ...state.movingApplications,
          movingApplications: {
            ...state.movingApplications.movingApplications,
            ...action.payload,
          },
        },
      }
    }
    case ActionTypes.SET_ACTIVE_MOVING_APPLICATIONS: {
      return {
        ...state,
        movingApplications: {
          ...state.movingApplications,
          activeMovingApplication: {
            ...state.movingApplications.activeMovingApplication,
            ...action.payload,
          },
        },
      }
    }
    case ActionTypes.SET_PAYMENT_PROMISES: {
      return produce(state, (draft) => {
        if (action.payload.steps) {
          draft.movingApplications.tempPaymentPromises.steps = action.payload.steps
        }

        if (action.payload.checkedBank) {
          draft.movingApplications.tempPaymentPromises.checkedBank = action.payload.checkedBank
        }

        if (action.payload.formState) {
          draft.movingApplications.tempPaymentPromises.formState = action.payload.formState
        }

        if (action.payload.tempAccountTransferCalendar) {
          draft.movingApplications.tempPaymentPromises.tempAccountTransferCalendar =
            action.payload.tempAccountTransferCalendar
        }
      })
    }
    case ActionTypes.SET_MAIN_EXAMINATION_SCORE: {
      return produce(state, (draft) => {
        if (action.payload.status) {
          draft.mainExaminationScore.status = action.payload.status
        }

        if (action.payload.value) {
          draft.mainExaminationScore.value = action.payload.value
        }
      })
    }
    default: {
      return state
    }
  }
}
