import React, { useEffect } from 'react'
import { FallbackProps } from 'react-error-boundary'
import styled from 'styled-components'
import { RedButton } from '../Button'
import error_icon from 'src/assets/images/icons/error.svg'
import { colorRed } from 'src/assets/styles/variable'

const Component: React.LFC<FallbackProps & { className?: string }> = ({
  error,
  resetErrorBoundary,
  className,
}) => {
  useEffect(() => {
    console.error(error)
  }, [error])

  return (
    <div role="alert" className={className}>
      <h1>
        <img src={error_icon} alt="" />
        {`申し訳ございません。処理中にエラーが発生しました。`}
      </h1>
      <pre>
        何度試してもこの画面が出てしまう場合は
        <br />
        お手数ですが、オペレーターにチャットでご報告ください。
      </pre>
      <RedButton onClick={resetErrorBoundary}>再試行する</RedButton>
    </div>
  )
}

const StyledComponent = styled(Component)`
  padding: 40px 5%;
  h1 {
    font-size: 20px;
    margin: 20px auto;
    color: ${colorRed};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    > img {
      display: block;
      width: 50px;
      margin-bottom: 8px;
    }
  }

  pre {
    font-size: 12px;
    padding: 10px 20px;
    text-align: center;
    margin-bottom: 14px;
  }

  > button {
    font-size: 18px;
    height: 48px;
  }
`

export const ErrorFallback = StyledComponent
