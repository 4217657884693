import { FetchStatus } from 'src/assets/types/store/fetchStatus'

export const ScheduleMethodTypes = {
  NEW: 'new',
  UPDATE: 'update',
  DELETE: 'delete'
} as const

export const ScheduleTypes = {
  VISIT_STORE: 'visit_store',
  VISIT_HOUSE: 'visit_house',
  ONLINE_CHAT: 'online_chat',
  ONLINE_TEL: 'online_tel'
} as const

export type ScheduleMethodType = typeof ScheduleMethodTypes[keyof typeof ScheduleMethodTypes]
export type ScheduleTypeValue = typeof ScheduleTypes[keyof typeof ScheduleTypes]
export type ScheduleType = {
  status: FetchStatus
  value: ScheduleTypeValue
}

export interface ISchedule {
  id?: string
  user_id?: number
  proposal_id?: number
  priority: number
  status?: string
  schedule_type: ScheduleTypeValue
  scheduled_at: string
  enabled?: boolean
  created_at?: string
  updated_at?: string
  method: ScheduleMethodType
}
