const envNames = ['local', 'development', 'production'] as const

type EnvName = typeof envNames[number]

const getEnvName = (): EnvName => {
  const envName = envNames.find((name) => {
    return name === process.env.ENV_NAME
  })
  if (envName) return envName
  throw new Error('wrong env name: ' + process.env.ENV_NAME)
}

export const ENV_NAME = getEnvName()
