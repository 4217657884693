import React from 'react'
import styled, { css } from 'styled-components'
import { MainButton } from '.'
import { colorGray, shadow } from 'src/assets/styles/variable'

type MainButtonWithReturnProps = {
  displayReturn: boolean
  handleReturnClick: () => void
  onClick: React.ComponentProps<'button'>['onClick']
  disabled: React.ComponentProps<'button'>['disabled']
}

const Component: React.FCX<MainButtonWithReturnProps> = (props) => {
  return (
    <div className={props.className}>
      {props.displayReturn && (
        <button
          className={`${props.className}__return`}
          onClick={props.handleReturnClick}
        >
          戻る
        </button>
      )}
      <div className={`${props.className}__main`}>
        {props.disabled ? (
          <button
            className={`${props.className}__gray`}
            onClick={props.onClick}
          >
            {props.children}
          </button>
        ) : (
          <MainButton
            onClick={(e) =>
              props.onClick(e as React.MouseEvent<HTMLButtonElement>)
            }
          >
            {props.children}
          </MainButton>
        )}
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 48px;

  &__gray,
  &__return {
    background: ${colorGray};
    ${shadow.bright};
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 1.4rem;
    outline: none;
    appearance: none;
    cursor: pointer;
    border: none;
    color: #ffffff;
    height: 100%;
  }

  &__return {
    width: 32%;
  }

  &__gray {
    width: 100%;
  }

  &__main {
    width: 66%;
    height: 100%;

    ${(props) =>
      !props.displayReturn &&
      css`
        width: 100%;
      `}
  }
`

export const MainButtonWithReturn = StyledComponent
