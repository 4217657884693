import { datadogLogs } from '@datadog/browser-logs'
import LogRocket from 'logrocket'
import * as mixpanel from 'mixpanel-browser'
import { useCallback, useState } from 'react'
import { ENV_NAME, MIXPANEL_TOKEN } from 'src/lib/constants'
import { LOGROCKET_ORG } from 'src/lib/constants/value'
import * as googleAnalytics from 'src/lib/page-directory/ga/analytics'

export const useInitializeApp = () => {
  const [init, setInit] = useState(false)

  const DATADOG_CONFIG = {
    SITE: 'ap1.datadoghq.com',
    SESSION_SAMPLE_RATE: 100,
    SERVICE_NAME: {
      DEVELOPMENT: 'smooth_liff_dev',
      PRODUCTION: 'smooth_liff_prod'
    }
  } as const

  const setPageView = useCallback((url: string) => {
    googleAnalytics.gaPageView(url)
  }, [])

  const initializeExternalApps = useCallback(() => {
    if (init) return

    // MIXPANEL
    mixpanel.init(MIXPANEL_TOKEN)

    // LogRocket
    if (ENV_NAME !== 'local') {
      LogRocket.init(LOGROCKET_ORG)
    }


    if (
      process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN &&
      process.env.ENV_NAME &&
      !['local', 'test'].includes(process.env.ENV_NAME)
    ) {
      datadogLogs.init({
        clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
        site: DATADOG_CONFIG.SITE,
        forwardErrorsToLogs: true,
        forwardConsoleLogs: 'all',
        sessionSampleRate: DATADOG_CONFIG.SESSION_SAMPLE_RATE,
        env: process.env.ENV_NAME,
        service: process.env.ENV_NAME === 'development'
          ? DATADOG_CONFIG.SERVICE_NAME.DEVELOPMENT
          : DATADOG_CONFIG.SERVICE_NAME.PRODUCTION,
        version: process.env.NEXT_PUBLIC_APP_VERSION || '1.0.0'
      })
    }

    setInit(true)
  }, [init])

  return {
    setPageView,
    initializeExternalApps,
  }
}
