import produce from 'immer'
import { HYDRATE } from 'next-redux-wrapper'
import {
  ISearchHousesView,
  SearchHousesData
} from 'src/assets/types/data/search_houses'
import { AreaSelector } from 'src/assets/types/data/search_houses/area_selector'
import { fetchStatus } from 'src/assets/types/store/fetchStatus'
import { BypassFormState } from 'src/hooks/useSearchHouses/useSeachHousesBypassForm'
import { BypassResultState } from 'src/hooks/useSearchHouses/useSearchHousesBypassResult'
import { SearchHousesFormType } from 'src/hooks/useSearchHousesFormType'
import { ISteps } from 'src/hooks/utils/useSteps'
import { Actions, ActionTypes } from 'src/store/actions/search-houses'

type SearchHousesState = {
  steps: ISteps['steps']
  searchHousesData: SearchHousesData
  view: ISearchHousesView
  areaSelector: AreaSelector
  formType: SearchHousesFormType
  bypassFormState: BypassFormState
  bypassResultState: BypassResultState
}

export const searchHousesState: SearchHousesState = {
  steps: { current: 1, max: 11 },
  formType: null,
  searchHousesData: {
    fetchStatus: fetchStatus.UNFETCHED,
    search_house: {
      id: null,
      prefecture: null,
      ambiguous: null,
      madori: [],
      breadth_lower: null,
      breadth_upper: null,
      building_age: '指定しない',
      build_type: [],
      build_const: [],
      special: [],
      requirement: null,
      house_urls: [''],
      rent_upper: null,
      target_cities: [],
      stations: [],
      moving_target_on: null,
      moving_purpose: [],
      walk: null,
      rent_lower: null
    },
    how_to_ask_store: null,
    is_for_the_first_time: null
  },
  view: {
    mainButtonText: '次へ',
    displaySelector: false,
    selectorType: null,
    selectorOpened: [],
    formValidation: false,
    touchedInputsIds: []
  },
  areaSelector: {
    data: {
      line: {},
      city: {}
    },
    state: {
      city: {
        cities: []
      },
      line: {
        stations: []
      },
      prefecture: null
    }
  },
  bypassFormState: {
    rentLower: null,
    rentUpper: null,
    steps: { current: 1, max: 3 }
  },
  bypassResultState: {
    selectedPropertyId: null,
    properties: [],
    searchingForProperty: false
  }
}

export const searchHousesReducer = (
  state = searchHousesState,
  action: Actions | { type: typeof HYDRATE; payload: SearchHousesState }
): SearchHousesState => {
  switch (action.type) {
    case ActionTypes.SET_SEARCH_HOUSES_STEPS_VALUE: {
      return {
        ...state,
        steps: {
          current: action.payload.current || state.steps.current,
          max: action.payload.max || state.steps.current
        }
      }
    }
    case ActionTypes.SET_SEARCH_HOUSES_FETCH_STATUS: {
      return {
        ...state,
        [action.payload.target]: {
          ...state[action.payload.target],
          fetchStatus: action.payload.fetchStatus
        }
      }
    }
    case ActionTypes.SET_VALUE: {
      return {
        ...state,
        searchHousesData: {
          ...state.searchHousesData,
          ...action.payload,
          search_house: {
            ...state.searchHousesData.search_house,
            ...action.payload.search_house
          }
        }
      }
    }
    case ActionTypes.MERGE_SEARCH_HOUSES_VALUE: {
      return {
        ...state,
        searchHousesData: {
          ...state.searchHousesData,
          ...action.payload,
          search_house: {
            ...state.searchHousesData.search_house,
            ...action.payload.search_house,
            building_age:
              action.payload?.search_house?.building_age ||
              state.searchHousesData?.search_house?.building_age ||
              '指定しない'
          }
        }
      }
    }
    case ActionTypes.SET_SEARCH_HOUSE_VALUES: {
      return {
        ...state,
        searchHousesData: {
          ...state.searchHousesData,
          search_house: {
            ...state.searchHousesData.search_house,
            ...action.payload
          }
        }
      }
    }
    case ActionTypes.SET_AREA_SELECTOR_STATE: {
      return produce(state, (draft) => {
        draft.areaSelector.state = action.payload
      })
    }
    case ActionTypes.SET_VIEWS: {
      return {
        ...state,
        view: {
          ...state.view,
          ...action.payload
        }
      }
    }
    case ActionTypes.SET_UPDATED_STATUS: {
      return {
        ...state,
        searchHousesData: {
          ...state.searchHousesData,
          fetchStatus: action.payload
        }
      }
    }
    case ActionTypes.SET_AREA_SELECTOR_DATA: {
      return {
        ...state,
        areaSelector: {
          ...state.areaSelector,
          data: {
            ...state.areaSelector.data,
            line: {
              ...state.areaSelector.data.line,
              ...action.payload.line
            },
            city: {
              ...state.areaSelector.data.city,
              ...action.payload.city
            }
          }
        }
      }
    }
    case ActionTypes.SET_FORM_TYPE: {
      return {
        ...state,
        formType: action.payload
      }
    }
    case ActionTypes.SET_BYPASS_VALUE: {
      return produce(state, (draft) => {
        draft.bypassFormState = action.payload
      })
    }
    case ActionTypes.SET_BYPASS_RESLUT: {
      return produce(state, (draft) => {
        draft.bypassResultState = action.payload
      })
    }
    case ActionTypes.SET_BYPASS_STATE: {
      return produce(state, (draft) => {
        draft.bypassFormState = action.payload(draft.bypassFormState)
      })
    }
    // そのうち消す
    case ActionTypes.SET_SEARCH_HOUSES_AREAS: {
      return {
        ...state,
        searchHousesData: {
          ...state.searchHousesData,
          search_house: {
            ...state.searchHousesData.search_house,
            prefecture:
              action.payload.prefecture ||
              state.searchHousesData.search_house.prefecture,
            stations:
              action.payload.stations ||
              state.searchHousesData.search_house.stations,
            target_cities:
              action.payload.target_cities ||
              state.searchHousesData.search_house.target_cities
          }
        }
      }
    }
    default: {
      return state
    }
  }
}
