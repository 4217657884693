import { useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ActiveProposalState, IActiveProposal } from 'src/assets/types/data/proposal/activeProposal'
import { fetchStatus } from 'src/assets/types/store/fetchStatus'
import { useAccessToken } from 'src/hooks/useAccessToken'
import { useHandleErrors } from 'src/hooks/utils/useHandleErrors'
import { useSmoothApi } from 'src/hooks/utils/useSmoothApi'
import { SMOOTH_LINE_API_URL } from 'src/lib/constants'
import { setActiveProposal } from 'src/store/actions/proposal'
import { proposalState } from 'src/store/reducer/proposal'

type ActiveProposal = {
  activeProposal: ActiveProposalState
  activeProposalId: string
  getActiveProposal: () => Promise<void>
  refreshActiveProposal: () => Promise<void>
  userId: string
}

export const useActiveProposal = (): ActiveProposal => {
  const { errorCallback } = useHandleErrors()
  const storeActiveProposal = useSelector((state) => state.proposal.activeProposal)
  const dispatch = useDispatch()
  const activeProposal = useMemo(() => storeActiveProposal, [storeActiveProposal])

  const { accessToken } = useAccessToken()
  const { getData } = useSmoothApi()

  const userId = useMemo(() => {
    if (activeProposal.proposal?.user_id) {
      return activeProposal.proposal?.user_id
    }
  }, [activeProposal.proposal])

  const fetcher = useCallback(async () => {
    if (!accessToken.decoded) return
    const url = `${SMOOTH_LINE_API_URL}/api/v4/users/proposals/active`
    dispatch(
      setActiveProposal({
        ...proposalState.activeProposal,
        fetchStatus: fetchStatus.FETCHING,
      })
    )
    const data: IActiveProposal = await getData<IActiveProposal>(url).catch((err) => {
      errorCallback(`${err} (${url})`, 'GET')
      throw new Error(err)
    })

    dispatch(
      setActiveProposal({
        fetchStatus: fetchStatus.FETCHED,
        ...data,
      })
    )
  }, [accessToken.decoded, dispatch, errorCallback, getData])

  const activeProposalId = useMemo(() => activeProposal.proposal?.id, [activeProposal.proposal])

  const getActiveProposal = useCallback(async () => {
    if (activeProposal.fetchStatus === fetchStatus.UNFETCHED) {
      await fetcher()
    }
  }, [activeProposal.fetchStatus, fetcher])

  const refreshActiveProposal = useCallback(async () => {
    await fetcher()
  }, [fetcher])

  return {
    activeProposal,
    activeProposalId,
    getActiveProposal,
    refreshActiveProposal,
    userId,
  }
}
