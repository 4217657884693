// import type { Liff } from '@line/liff'
import { ENV_NAME } from 'src/lib/constants'

// declare module '@line/liff' {
//   interface Liff {
//     init: ExtendedInit
//   }
// }

// declare global {
//   const liff: Liff & {
//     init: ExtendedInit
//   }
// }

export const getLiff = async () => {
  // [FIXME] これもLiffMockPluginにしたいけどjestで動かした時にエラー吐いてうざい
  if (process.env.NODE_ENV === 'test') {
    return (await import('tests/__mocks__/@line/liff'))
      .liff as typeof import('@line/liff').default
  }

  const importedLiff = await import('@line/liff').catch((err) => {
    throw err
  })

  const liff = importedLiff.default
  const shouldMockLiff = ENV_NAME === 'local' && !liff.isInClient()
  if (shouldMockLiff) {
    return (await import('tests/__mocks__/@line/liff'))
      .liff as typeof import('@line/liff').default
  }

  return liff
}
