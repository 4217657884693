import fetch from 'cross-fetch'
import { useAccessToken } from 'src/hooks/useAccessToken'

export const useSmoothApiFetcher = () => {
  const { accessToken } = useAccessToken()

  const smoothApiFetcher: typeof fetch = async (input, init) => {
    const options = init ?? {}

    options.headers = {
      ...(options.headers ?? {}),
      Authorization: `Bearer ${accessToken.decoded}`
    }

    return fetch(input, options)
  }

  return {
    smoothApiFetcher
  }
}
