import {
  ILineProps,
  IPixelProps,
  IUserProps,
  AgreementStatusState
} from 'src/assets/types/user'
import { IDocumentsData } from 'src/containers/Documents/types'

export const ActionTypes = {
  SET_LINE_PROPS: 'SET_LINE_PROPS',
  SET_PIXEL_PROPS: 'SET_PIXEL_PROPS',
  SET_USER_PROPS: 'SET_USER_PROPS',
  SET_MYPAGE: 'SET_MYPAGE',
  SET_DOCUMENTS: 'SET_DOCUMENTS',
  SET_AGREEMENT_STATUS: 'SET_AGREEMENT_STATUS',
  SET_ACTIVE_MOVING_APPLICATION: 'USER@@SET_ACTIVE_MOVNG_APPLICATION'
} as const

type SetLinePropsAction = {
  type: typeof ActionTypes.SET_LINE_PROPS
  payload: RequireOne<{ [key in keyof ILineProps]?: ILineProps[key] }>
}

type SetPixelPropsAction = {
  type: typeof ActionTypes.SET_PIXEL_PROPS
  payload: RequireOne<{ [key in keyof IPixelProps]?: IPixelProps[key] }>
}

type SetDocumentsAction = {
  type: typeof ActionTypes.SET_DOCUMENTS
  payload: IDocumentsData
}

type SetUserPropsAction = {
  type: typeof ActionTypes.SET_USER_PROPS
  payload: RequireOne<{ [key in keyof IUserProps]?: IUserProps[key] }>
}

type SetAgreementStatusAction = {
  type: typeof ActionTypes.SET_AGREEMENT_STATUS
  payload: Partial<AgreementStatusState>
}

export type Actions =
  | SetLinePropsAction
  | SetDocumentsAction
  | SetPixelPropsAction
  | SetUserPropsAction
  | SetAgreementStatusAction
